import { createContext, useContext, useEffect, useState } from 'react';
import { auth, db } from './firebase'; // Ensure the correct path
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged
} from 'firebase/auth';
import { setDoc, doc, getDoc } from 'firebase/firestore';

let logoutTimeout;

const setLogoutTimer = () => {
  clearTimeout(logoutTimeout);
  logoutTimeout = setTimeout(async () => {
    try {
      await signOut(auth);
      alert('You have been logged out due to inactivity.');
    } catch (error) {
      console.error('Logout error:', error);
    }
  }, 2 * 60 * 60 * 1000); // 2 hours
};

const resetTimer = () => {
  setLogoutTimer();
};

if (typeof window !== 'undefined') {
  window.onload = resetTimer;
  window.onmousemove = resetTimer;
  window.onkeypress = resetTimer;
}

const registerWithEmail = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    await setDoc(doc(db, 'users', user.uid), {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName || '',
      photoURL: user.photoURL || ''
    });

    setLogoutTimer();
    return user;
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
};

const registerWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const userDoc = doc(db, 'users', user.uid);
    const userSnap = await getDoc(userDoc);

    if (!userSnap.exists()) {
      await setDoc(userDoc, {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName || '',
        photoURL: user.photoURL || ''
      });
    }

    setLogoutTimer();
    return user;
  } catch (error) {
    console.error('Google registration error:', error);
    throw new Error(error.message);
  }
};

const loginWithEmail = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    setLogoutTimer();
    return userCredential.user;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

const loginWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const userDoc = doc(db, 'users', user.uid);
    const userSnap = await getDoc(userDoc);

    if (!userSnap.exists()) {
      await setDoc(userDoc, {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName || '',
        photoURL: user.photoURL || ''
      });
    }

    setLogoutTimer();
    return user;
  } catch (error) {
    console.error('Google login error:', error);
    throw error;
  }
};

const logout = async () => {
  try {
    await signOut(auth);
    clearTimeout(logoutTimeout);
  } catch (error) {
    console.error('Logout error:', error);
    throw error;
  }
};

const getCurrentUser = (callback = () => {}) => { // Default to an empty function
  const unsubscribe = onAuthStateChanged(auth, user => {
    if (user) {
      resetTimer();
    }
    if (typeof callback === 'function') {
      callback(user);
    } else {
      console.error('Callback is not a function');
    }
  });
  return unsubscribe;
};



const fetchUserProfile = async (uid) => {
  if (!uid) {
    console.error('Invalid or missing user ID.');
    throw new Error('Invalid or missing user ID.');
  }

  try {
    const userDocRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      return userDoc.data();
    } else {
      console.error('No such document for the user profile!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

// Add this function to check admin status
const isAdmin = async (uid) => {
  try {
    const profile = await fetchUserProfile(uid);
    return profile && profile.isAdmin; // Assuming `isAdmin` field exists
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
};


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setUser(user); // Set user when authentication state changes
    });

    return () => unsubscribe(); // Clean up subscription on unmount
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export {
  registerWithEmail,
  registerWithGoogle,
  loginWithEmail,
  loginWithGoogle,
  logout,
  fetchUserProfile,
  getCurrentUser,
  isAdmin
};
