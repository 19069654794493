import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser} from '../auth';
import '../styles/Navbar.css';
import logo from '../pics/logo192.png';
import Register from '../authpanel/Register';
import SignIn from '../authpanel/SignIn';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import bookIcon from '../assets/book.svg';
import capIcon from '../assets/cap.svg';
import coinIcon from '../assets/coin.svg';
import boltIcon from '../assets/bolt.svg';
import homeIcon from '../assets/home.svg';
import adminIcon from '../assets/admin.svg';
import userIcon from '../assets/user.svg';

const Navbar = () => {
  const [isRegisterVisible, setRegisterVisible] = React.useState(false);
  const [isLoginVisible, setLoginVisible] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = getCurrentUser(async (user) => {
      setUser(user);
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setIsAdmin(userDoc.data().isAdmin || false);
        }
      }
    });
    return () => unsubscribe();
  }, [db]);

  return (
    <div className='navbar-container'>
      <div className="navbar">
        <div className="nav-left">
          <img src={logo} className="logo" alt="Anglomova Logo" />
          <div className="nav-title">Anglomova</div>
        </div>
        <div className="nav-panel">
          {user ? (
          <>
            
          </> 
        ) : (
          
          <NavPanelElement label="Main" icon={homeIcon} onClick={() => navigate('/')} />
            
        )
      }
          <NavPanelElement label="Courses" icon={capIcon} onClick={() => navigate('/courses')} />
          <NavPanelElement label="Campaign" icon={boltIcon} onClick={() => navigate('/lesson')} />
          <NavPanelElement label="Vault" icon={bookIcon} onClick={() => navigate('/vault')} />
          <NavPanelElement label="Pricing" icon={coinIcon} onClick={() => navigate('/pricing')} />
          {isAdmin && (
            <NavPanelElement label="Admin" icon={adminIcon} onClick={() => navigate('/admin')} />
          )}
        </div>
        <div className="nav-auth" id="auth-button">
          {user ? (
            <>
              <NavPanelElement label="Profile" icon={userIcon} onClick={() => navigate('/profile')} />
            </>
          ) : (
            <div className='nav-auth noMob'>
              <button className="nav-auth-sign" onClick={() => setLoginVisible(true)}>Sign in</button>
              <button className="nav-auth-reg" onClick={() => setRegisterVisible(true)}>Register</button>
            </div>
          )}
        </div>
      </div>

      {isRegisterVisible && (
        <Overlay title="REGISTER" onClose={() => setRegisterVisible(false)}>
          <Register onClose={() => setRegisterVisible(false)} />
          <p>Already have an account? <span className='changeOver' onClick={() => { setRegisterVisible(false); setLoginVisible(true); }}>SIGN IN</span></p>
        </Overlay>
      )}
      {isLoginVisible && (
        <Overlay title="LOG IN" onClose={() => setLoginVisible(false)}>
          <SignIn onClose={() => setLoginVisible(false)} />
          <p>Don't have an account? <span className='changeOver' onClick={() => { setLoginVisible(false); setRegisterVisible(true); }}>REGISTER</span></p>
        </Overlay>
      )}
    </div>
  );
};

const NavPanelElement = ({ label, icon, onClick }) => (
  <div className="nav-panel-element" onClick={onClick}>
    <img src={icon} alt={`${label} icon`} className="nav-icon" />
    <span>{label}</span>
  </div>
);


const Overlay = ({ title, onClose, children }) => (
  <div className="overlay">
    <div className="overlay-content reg">
      <h2>{title}</h2>
      <div className="close-btn" onClick={onClose}> </div>
      {children}
    </div>
  </div>
);

export default Navbar;