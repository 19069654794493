import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const auth = getAuth();
const db = getFirestore(); // Moved out of component so it can be exported

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = sessionStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const updateUser = async (currentUser) => {
      if (!currentUser) {
        setUser(null);
        sessionStorage.removeItem('user');
        setLoading(false);
        return;
      }

      try {
        // Fetch the user's custom claims to determine their role
        const idTokenResult = await currentUser.getIdTokenResult();
        const role = idTokenResult.claims.role || 'user'; // Default to 'user' if no role is set

        // Fetch user data from Firestore
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);

        let userData = {
          uid: currentUser.uid,
          email: currentUser.email,
          role,
          ...(userDoc.exists() ? userDoc.data() : {}),
        };

        // Cache user data in sessionStorage
        sessionStorage.setItem('user', JSON.stringify(userData));
        setUser(userData);
      } catch (error) {
        console.error('Error fetching user data or custom claims:', error);
        setError('Failed to load user data.');
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      updateUser(currentUser); // Removed debounce for simplicity
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const login = async (email, password) => {
    setLoading(true);
    setError(null);
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error('Login error:', error);
      setError('Failed to log in. Please check your credentials and try again.');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    setLoading(true);
    setError(null);
    try {
      await signOut(auth);
      setUser(null);
      sessionStorage.removeItem('user');
    } catch (error) {
      console.error('Logout error:', error);
      setError('Failed to log out. Please try again.');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const value = {
    user,
    login,
    logout,
    loading,
    error,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

// Export db so it can be used in other files
export { db };
