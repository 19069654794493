import React, { createContext, useContext, useState, useCallback } from 'react';

const OverlayContext = createContext();

export const useOverlayContext = () => useContext(OverlayContext);

export const OverlayProvider = ({ children }) => {
  const [isRegisterVisible, setRegisterVisible] = useState(false);
  const [isLoginVisible, setLoginVisible] = useState(false);

  const showRegister = useCallback(() => setRegisterVisible(true), []);
  const hideRegister = useCallback(() => setRegisterVisible(false), []);

  const showLogin = useCallback(() => setLoginVisible(true), []);
  const hideLogin = useCallback(() => setLoginVisible(false), []);

  const closeOverlays = useCallback(() => {
    setRegisterVisible(false);
    setLoginVisible(false);
    // Add more overlay states to close if necessary
  }, []);

  return (
    <OverlayContext.Provider value={{
      isRegisterVisible,
      isLoginVisible,
      showRegister,
      hideRegister,
      showLogin,
      hideLogin,
      closeOverlays,  // Make sure this is included in the context value
    }}>
      {children}
    </OverlayContext.Provider>
  );
};
