// LearningContext.js

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { collection, doc, getDocs, getDoc, writeBatch, arrayUnion, setDoc } from 'firebase/firestore';
import { useAuth } from './AuthContext';
import { db } from '../firebase';

const LearningContext = createContext();

export const useLearning = () => useContext(LearningContext);

export const LearningProvider = ({ children }) => {
  const { user, loading: authLoading } = useAuth();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [nextLesson, setNextLesson] = useState(null);
  const [nextWorkbook, setNextWorkbook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State for user interactions
  const [userInteractions, setUserInteractions] = useState({});
  const [currentLessonOrWorkbookID, setCurrentLessonOrWorkbookID] = useState(null);

  const fetchUserAndCourseData = useCallback(async () => {
    if (!user) return;

    setLoading(true);
    setError(null);

    try {
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.exists() ? userDoc.data() : {};

      const finishedLessons = userData.finishedLessons || [];
      const finishedWorkbooks = userData.finishedWorkbooks || [];

      if (user.courseID) {
        const courseDocRef = doc(db, 'courses', user.courseID);
        const courseDoc = await getDoc(courseDocRef);

        if (courseDoc.exists()) {
          const courseData = { id: courseDoc.id, ...courseDoc.data() };

          const modulesSnapshot = await getDocs(collection(courseDocRef, 'modules'));
          const modulesData = await Promise.all(
            modulesSnapshot.docs.map(async (moduleDoc) => {
              const moduleData = { id: moduleDoc.id, ...moduleDoc.data() };

              const lessonsSnapshot = await getDocs(collection(courseDocRef, 'modules', moduleDoc.id, 'lessons'));
              const lessonsData = await Promise.all(
                lessonsSnapshot.docs.map(async (lessonDoc) => {
                  const lessonData = {
                    id: lessonDoc.id,
                    moduleID: moduleDoc.id,
                    ...lessonDoc.data(),
                  };

                  // Fetch user interactions for the lesson
                  const interactionRef = doc(
                    db,
                    'courses',
                    user.courseID,
                    'modules',
                    moduleDoc.id,
                    'lessons',
                    lessonDoc.id,
                    'interactions',
                    user.uid
                  );
                  const interactionDoc = await getDoc(interactionRef);
                  if (interactionDoc.exists()) {
                    lessonData.userInteractions = interactionDoc.data();
                  } else {
                    lessonData.userInteractions = {};
                  }

                  return lessonData;
                })
              );

              const workbooksSnapshot = await getDocs(collection(courseDocRef, 'modules', moduleDoc.id, 'workbooks'));
              const workbooksData = await Promise.all(
                workbooksSnapshot.docs.map(async (workbookDoc) => {
                  const workbookData = {
                    id: workbookDoc.id,
                    moduleID: moduleDoc.id,
                    ...workbookDoc.data(),
                  };

                  // Fetch user interactions for the workbook
                  const interactionRef = doc(
                    db,
                    'courses',
                    user.courseID,
                    'modules',
                    moduleDoc.id,
                    'workbooks',
                    workbookDoc.id,
                    'interactions',
                    user.uid
                  );
                  const interactionDoc = await getDoc(interactionRef);
                  if (interactionDoc.exists()) {
                    workbookData.userInteractions = interactionDoc.data();
                  } else {
                    workbookData.userInteractions = {};
                  }

                  return workbookData;
                })
              );

              return {
                ...moduleData,
                lessons: lessonsData,
                workbooks: workbooksData,
              };
            })
          );

          const fullCourseData = {
            ...courseData,
            modules: modulesData,
          };

          setSelectedCourse(fullCourseData);

          // Flatten lessons and workbooks for easier access
          const allLessons = modulesData.flatMap((module) => module.lessons || []);
          const allWorkbooks = modulesData.flatMap((module) => module.workbooks || []);

          // Filter out finished lessons and workbooks
          const unfinishedLessons = allLessons.filter(
            (lesson) => !finishedLessons.some((finished) => finished.lessonID === lesson.id)
          );
          const unfinishedWorkbooks = allWorkbooks.filter(
            (workbook) => !finishedWorkbooks.some((finished) => finished.workbookID === workbook.id)
          );

          // Set the next lesson and workbook
          setNextLesson(unfinishedLessons[0] || null);
          setNextWorkbook(unfinishedWorkbooks[0] || null);
        } else {
          setError('Selected course does not exist.');
        }
      }
    } catch (err) {
      console.error('Error fetching user or course data:', err);
      setError('Failed to fetch course data.');
    } finally {
      setLoading(false);
    }
  }, [user]);

  // Fetch data on mount
  useEffect(() => {
    if (!authLoading && user) {
      fetchUserAndCourseData();
    } else {
      setLoading(false);
    }
  }, [user, authLoading, fetchUserAndCourseData]);

  // Function to save interactions immediately
  const saveInteractionsImmediately = async () => {
    if (!user || !currentLessonOrWorkbookID) return;

    try {
      const [type, moduleID, contentID] = currentLessonOrWorkbookID.split('/');
      const interactionRef = doc(
        db,
        'courses',
        user.courseID,
        'modules',
        moduleID,
        type === 'lesson' ? 'lessons' : 'workbooks',
        contentID,
        'interactions',
        user.uid
      );
      await setDoc(interactionRef, userInteractions);
    } catch (error) {
      console.error('Error saving user interactions:', error);
      setError('Failed to save interactions.');
    }
  };

  // Debounce save to Firebase to minimize writes
  useEffect(() => {
    const saveInteractionsToFirebase = async () => {
      await saveInteractionsImmediately();
    };

    const handler = setTimeout(() => {
      saveInteractionsToFirebase();
    }, 500); // Reduced debounce delay to 500ms

    return () => {
      clearTimeout(handler);
    };
  }, [userInteractions, user, currentLessonOrWorkbookID]);

  // Handle saving interactions when the user closes the app or lesson window
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Save interactions immediately
      saveInteractionsImmediately();
      // Note: We cannot reliably perform async operations here
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [userInteractions, user, currentLessonOrWorkbookID]);

  const updateUserInteraction = (key, interaction) => {
    setUserInteractions((prevInteractions) => ({
      ...prevInteractions,
      [key]: interaction,
    }));
  };

  const finishLesson = async (lessonID, lessonStats = {}) => {
    try {
      const userRef = doc(db, 'users', user.uid);

      const [moduleID, contentID] = lessonID.split('/');

      const interactionRef = doc(
        db,
        'courses',
        user.courseID,
        'modules',
        moduleID,
        'lessons',
        contentID,
        'interactions',
        user.uid
      );

      const batch = writeBatch(db);

      const newFinishedLesson = {
        lessonID: contentID,
        ...lessonStats,
        date: new Date().toISOString(),
      };

      batch.update(userRef, {
        finishedLessons: arrayUnion(newFinishedLesson),
        currentLessonID: lessonID,
      });

      // Save user interactions for the lesson
      batch.set(interactionRef, userInteractions);

  

      await batch.commit();

      fetchUserAndCourseData();
    } catch (error) {
      console.error('Error finishing lesson:', error);
      setError('Failed to finish the lesson.');
    }
  };

  const finishWorkbook = async (workbookID, workbookStats = {}) => {
    try {
      const userRef = doc(db, 'users', user.uid);

      const [moduleID, contentID] = workbookID.split('/');

      const interactionRef = doc(
        db,
        'courses',
        user.courseID,
        'modules',
        moduleID,
        'workbooks',
        contentID,
        'interactions',
        user.uid
      );

      const batch = writeBatch(db);

      const newFinishedWorkbook = {
        workbookID: contentID,
        ...workbookStats,
        date: new Date().toISOString(),
      };

      batch.update(userRef, {
        finishedWorkbooks: arrayUnion(newFinishedWorkbook),
        currentWorkbookID: workbookID,
      });

      // Save user interactions for the workbook
      batch.set(interactionRef, userInteractions);

      await batch.commit();

      fetchUserAndCourseData();
    } catch (error) {
      console.error('Error finishing workbook:', error);
      setError('Failed to finish the workbook.');
    }
  };

  return (
    <LearningContext.Provider
      value={{
        selectedCourse,
        nextLesson,
        nextWorkbook,
        finishLesson,
        finishWorkbook,
        loading,
        error,
        userInteractions,
        updateUserInteraction,
        setUserInteractions,
        setCurrentLessonOrWorkbookID,
      }}
    >
      {!loading && children}
    </LearningContext.Provider>
  );
};
