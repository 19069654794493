import React, { useState } from 'react';
import { loginWithEmail, loginWithGoogle } from '../auth';
import { useNavigate } from 'react-router-dom';
import GoogleIcon from '../pics/googleIco.png';

const SignIn = ({ onClose }) => {  // Ensure onClose is passed here
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await loginWithEmail(email, password);
      navigate('/profile');
      onClose(); // Close the overlay after successful login
    } catch (error) {
      alert("Login failed: " + error.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await loginWithGoogle();
      navigate('/profile');
      onClose(); // Close the overlay after successful login
    } catch (error) {
      alert("Google login failed: " + error.message);
    }
  };

  return (
    <form onSubmit={handleLogin}>
      <input 
        type="email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
        placeholder="Email" 
        required 
      />
      <input 
        type="password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
        placeholder="Password" 
        required 
      />
      <button className="btn" type="submit">Sign In</button>
      <button className="btn-gg" type="button" onClick={handleGoogleLogin}>
        <img className="iconic" src={GoogleIcon} alt="Google Icon" />
        Sign in with Google
      </button>
    </form>
  );
};

export default SignIn;
