import React, { Suspense, lazy } from 'react';
import Navbar from './components/Navbar';
import { AuthProvider } from './contexts/AuthContext';
import { LearningProvider } from './contexts/LearningContext';
import { OverlayProvider } from './components/overlayManager';
import './styles/App.css';

const AppRouter = lazy(() => import('./AppRouter'));

const App = () => {
  return (
    <AuthProvider>
      <OverlayProvider>
        <LearningProvider>
          <Navbar />
          <Suspense fallback={<div>.</div>}>

            <AppRouter />

          </Suspense>
        </LearningProvider>
      </OverlayProvider>
    </AuthProvider>
  );
};

export default App;
